// src/App.jsx
import { Route, Routes } from "react-router-dom";
import Home from "./page/Home/index.jsx";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./page/Login/index.jsx";
import SignIn from "./page/Signin/index.jsx";
import {
  ForgotPasswordmodal,
  ComprehensiveListingsmodal,
  EnhancedVisibilitymodal,
  NetworkingOpportunitiesmodal,
  ChangepasswordModel,
  LogoutModel,
  LoginModel,
} from "./components/UI/Modals/index.jsx";
import GlobalContextProvider from "./Globalcontext.jsx";
import ComprehensiveListings from "./page/Learnmore_page1/index.jsx";
import EnhancedVisibility from "./page/Learnmore_page2/index.jsx";
import NetworkingOpportunities from "./page/Learnmore_page3/index.jsx";
import ScrollToTop from "./components/UI/ScrollTop.jsx";
import Contactus from "./page/ContactUS/index.jsx";
import Thankyou from "./page/thankyou/index.jsx";
import Countries from "./page/Countries/index.jsx";

import Industries from "./page/Industries/index.jsx";
import useFirebaseToken from "./Common/token/useFirebaseToken.jsx";
import Business from "./page/Business/index.jsx";
import CompanyList from "./page/CompanyList/index.jsx";
import BusinessDetails from "./page/BusinessDetails/index.jsx";
import Profile from "./page/Profile/index.jsx";
import VerifyYourBusiness from "./page/VerfymyBusiness/index.jsx";
import ShowmyBusiness from "./page/ShowMyBusiness/index.jsx";
import EditMyBusiness from "./page/EditMyBusiness/index.jsx";
import Trems from "./page/Trems/index.jsx";
import Privacy from "./page/PrivacyPolicy/index.jsx";
import SafetySecurity from "./page/Safety&Security/index.jsx";
import Guidelines from "./page/Guidelines/index.jsx";
import Appleappsiteassociation from "./page/Association/index.jsx";

function App() {
  const token = useFirebaseToken();

  return (
    <GlobalContextProvider>
      <ForgotPasswordmodal />
      <ChangepasswordModel />
      <LogoutModel />
      <LoginModel />
      <div className="App">
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Signup" element={<SignIn />} />
          <Route
            path="/ComprehensiveListings"
            element={<ComprehensiveListings />}
          />
          <Route path="/EnhancedVisibility" element={<EnhancedVisibility />} />
          <Route
            path="/NetworkingOpportunities"
            element={<NetworkingOpportunities />}
          />
          <Route path="/ContactUs" element={<Contactus />} />
          <Route path="/Industries" element={<Industries />} />
          <Route path="/Thankyou" element={<Thankyou />} />
          <Route path="/Countries" element={<Countries />} />
          <Route path="/Business" element={<Business />} />
          <Route path="/CompanyList" element={<CompanyList />} />
          <Route path="/BusinessDetails" element={<BusinessDetails />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/AddYourBusiness" element={<VerifyYourBusiness />} />
          <Route path="/ShowMyBusiness" element={<ShowmyBusiness />} />
          <Route path="/EditMyBusiness" element={<EditMyBusiness />} />
          <Route path="/TermsofUse" element={<Trems />} />
          <Route path="/PrivacyPolicy&Cookies" element={<Privacy />} />
          <Route path="/SafetySecurity" element={<SafetySecurity />} />
          <Route path="/Guidelines" element={<Guidelines />} />
          <Route
            path="/.well-known/apple-app-site-association"
            element={<Appleappsiteassociation />}
          />
        </Routes>
      </div>
    </GlobalContextProvider>
  );
}

export default App;
//  arbaz1805@gmail.com 123456
