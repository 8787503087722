import React from "react";
import JesonData from "../../Common/jeson/apple-app-site-association.json";

const Index = () => {
  return (
    <div>
      <pre>{JSON.stringify(JesonData, null, 2)}</pre>
    </div>
  );
};

export default Index;
