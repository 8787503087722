import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Index = () => {
  const [lang, setLang] = useState();
  const pathname = useLocation();
  const getname = pathname?.state?.pathname?.pathname;

  useEffect(() => {
    setLang(getname);
  }, [pathname]);

  const Navigation = useNavigate();

  return (
    <div className="main_div">
      <div className="arrow">
        <button
          onClick={() =>
            lang == "/Turkish"
              ? Navigation("/Turkish")
              : lang == "/French"
              ? Navigation("/French")
              : lang == "/Portuguese"
              ? Navigation("/Portuguese")
              : lang == "/Swahili"
              ? Navigation("/Swahili")
              : lang == "/Spanish"
              ? Navigation("/Spanish")
              : Navigation("/")
          }
          className="button-27"
          role="button">
          &#8592;
        </button>
      </div>

      <h3 className={lang == "/French" ? "head_text_french" : "head_text"}>
        {lang == "/Turkish"
          ? "TürkAfrica.Directory Gizlilik Politikası ve Çerezler"
          : lang == "/French"
          ? "Politique de confidentialité et cookies de TürkAfrica.Directory"
          : lang == "/Portuguese"
          ? "Política de Privacidade e Cookies do TürkAfrica.Directory"
          : lang == "/Swahili"
          ? "Sera ya Faragha na Vidakuzi ya TürkAfrica.Directory"
          : lang == "/Spanish"
          ? "Política de privacidad y cookies de TürkAfrica.Directory"
          : "TürkAfrica.Directory Privacy Policy & Cookies"}
      </h3>

      <div className="contacnt_div">
        {lang == "/Turkish" ? (
          <>
            <div className="sub_title_heading">
              <h6>TürkAfrica.Directory Gizlilik Politikası</h6>
              <h6>Son Güncelleme: 18 Eylül 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Giriş</h1>
              <p>
                TürkAfrica.Directory'a hoş geldiniz, Afrika ve Türkiye arasında
                güçlü iş bağlantıları kurmayı amaçlayan önde gelen işten işe
                (B2B) platform. Bu Gizlilik Politikası, hizmetlerimizi
                kullandığınızda kişisel bilgilerinizi nasıl topladığımızı,
                kullandığımızı, koruduğumuzu ve paylaştığımızı açıklar.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Topladığımız Bilgiler</h1>
              <p>
                Platformumuza kaydolduğunuzda, sipariş verdiğinizde veya
                hizmetlerimizle etkileşim kurduğunuzda şu bilgileri toplarız:
                <br />• Kişisel Kimlik Bilgileri: Adınız, e-posta adresiniz,
                telefon numaranız ve iş bilgileri.
                <br />• İşlemsel Bilgiler: Platformumuzda yapılan işlemler ve
                siparişlerin detayları.
                <br />• Kullanım Verileri: Web sitemizi ve hizmetlerimizi nasıl
                kullandığınıza dair bilgiler.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Verilerinizi Nasıl Kullanıyoruz?</h1>
              <p>
                Kişisel verilerinizi şunlar için kullanıyoruz:
                <br />• Hizmetlerimizi Sağlamak: İşlemleri işlemek, siparişleri
                tamamlamak ve müşteri desteği sunmak.
                <br />• Platformumuzu Geliştirmek: Kullanıcı davranışlarını
                analiz etmek ve yeni özellikler geliştirmek.
                <br />• Pazarlama (Onayla): Yalnızca onay verdiyseniz promosyon
                mesajları göndermek.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Verilerin Paylaşılması ve İfşa Edilmesi</h1>
              <p>
                Bilgilerinizi şu kişilerle paylaşabiliriz:
                <br />• Hizmet Sağlayıcılar: İş operasyonlarımıza yardımcı olan
                iş ortakları (ör. ödeme işlemcileri, lojistik hizmetleri).
                <br />• Yasal Uyum: Kanunen gerektiğinde veya yasal haklarımızı
                korumak için.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Veri Güvenliği</h1>
              <p>
                Kişisel bilgilerinizi yetkisiz erişim, kayıp veya ihlallere
                karşı korumak için endüstri standardı güvenlik önlemleri (ör.
                şifreleme) uyguluyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Veri Saklama</h1>
              <p>
                Verilerinizi, hizmetlerimizi sunmak için gereken süre boyunca
                veya yasaların gerektirdiği süre boyunca saklarız. Artık gerekli
                olmadığında, verileriniz güvenli bir şekilde silinir veya
                anonimleştirilir.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Haklarınız</h1>
              <p>
                UK Genel Veri Koruma Yönetmeliği (GDPR) kapsamında, kişisel
                verilerinize erişme, düzeltme, silme veya verilerinizin
                işlenmesini sınırlama hakkınız vardır. Bu haklarınızı kullanmak
                için lütfen bizimle iletişime geçin.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Uluslararası Veri Aktarımları</h1>
              <p>
                Kişisel bilgilerinizi İngiltere dışına aktardığımızda, geçerli
                yasal çerçevelere uygun olarak koruma sağlıyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>9. İletişim Bilgileri</h1>
              <p>
                Gizlilikle ilgili herhangi bir soru için, lütfen açılış
                sayfamızdaki ‘İletişim’ bölümünden bize ulaşın.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Politika Güncellemeleri</h1>
              <p>
                Bu Gizlilik Politikasını zaman zaman güncelleyebiliriz. Önemli
                değişiklikler yapıldığında, platformumuz üzerinden size bildirim
                göndereceğiz. Lütfen güncellemelerden haberdar olmak için
                periyodik olarak kontrol edin.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>TürkAfrica.Directory Çerez Politikası</h6>
              <h6>Son Güncelleme: 18 Eylül 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Giriş </h1>
              <p>
                Çerezler, kullanıcı deneyimini geliştirmek için cihazınızda
                saklanan küçük metin dosyalarıdır. Bu Çerez Politikası,
                TürkAfrica.Directory platformunda çerezleri nasıl ve neden
                kullandığımızı açıklar.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Kullandığımız Çerez Türleri </h1>
              <p>
                Temel Çerezler: Platformun ana işlevsellikleri için gereklidir.
                <br />
                Analitik Çerezler: Platformumuzu nasıl kullandığınızı anlamak
                için kullanılır.
                <br />
                Pazarlama Çerezleri: Tercihlerinize ve davranışlarınıza göre
                reklamları ve promosyon mesajlarını kişiselleştirir.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Çerezlerin Yönetimi</h1>
              <p>
                Çerezleri tarayıcı ayarlarınızdan yönetebilir veya
                engelleyebilirsiniz. Zorunlu olmayan çerezler için ilk ziyaret
                ettiğinizde onay istenir ve istediğiniz zaman onayınızı geri
                çekebilirsiniz.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Çerez Politikasındaki Değişiklikler</h1>
              <p>
                Bu Çerez Politikasını zaman zaman güncelleyebiliriz. Önemli
                değişiklikler yapıldığında platformumuz üzerinden size bildirim
                göndereceğiz.
              </p>
            </div>
            <div className="text_div">
              <h1>5. İletişim Bilgileri</h1>
              <p>
                Çerez Politikası ile ilgili herhangi bir sorunuz varsa, lütfen
                açılış sayfamızdaki ‘İletişim’ bölümünden bize ulaşın.
              </p>
            </div>
          </>
        ) : lang == "/French" ? (
          <>
            <div className="sub_title_heading">
              <h6>Politique de Confidentialité de TürkAfrica.Directory</h6>
              <h6>Dernière mise à jour : 18 septembre 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Bienvenue sur TürkAfrica.Directory, une plateforme dédiée à
                renforcer les connexions commerciales entre l'Afrique et la
                Turquie. Cette Politique de Confidentialité explique comment
                nous collectons, utilisons, protégeons et partageons vos
                informations personnelles lorsque vous utilisez nos services.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Informations que nous collectons</h1>
              <p>
                Nous collectons les informations personnelles suivantes lorsque
                vous vous inscrivez sur notre plateforme, passez des commandes
                ou interagissez avec nos services :
                <br />• Informations d'Identification Personnelle : Inclut votre
                nom, votre adresse e-mail, votre numéro de téléphone et vos
                informations professionnelles.
                <br />• Informations transactionnelles : Détails des
                transactions et commandes effectuées via notre plateforme.
                <br />• Données d'utilisation : Informations sur la façon dont
                vous naviguez et utilisez notre site Web et nos services.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Comment nous utilisons vos données</h1>
              <p>
                Nous utilisons vos données personnelles pour :
                <br />• Fournir nos services : Traiter les transactions,
                exécuter les commandes et offrir un support client.
                <br />• Améliorer notre plateforme : Analyser le comportement
                des utilisateurs pour améliorer la plateforme et développer de
                nouvelles fonctionnalités.
                <br />• Marketing (avec consentement) : Envoyer des messages
                promotionnels et des offres spéciales, mais uniquement si vous y
                avez consenti.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Partage et divulgation des données</h1>
              <p>
                Nous pouvons partager vos informations avec :
                <br />• Fournisseurs de services : Partenaires qui nous aident à
                gérer nos opérations commerciales (par ex. processeurs de
                paiements, services logistiques).
                <br />• Conformité légale : Lorsque la loi l'exige ou pour
                protéger nos droits légaux.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Sécurité des données</h1>
              <p>
                Nous prenons la sécurité au sérieux et mettons en œuvre des
                mesures de sécurité conformes aux normes de l'industrie, telles
                que le chiffrement et les contrôles d'accès, pour protéger vos
                informations personnelles contre tout accès non autorisé, perte
                ou violation.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Conservation des données</h1>
              <p>
                Nous conservons vos informations personnelles aussi longtemps
                qu'elles sont nécessaires pour fournir nos services ou comme
                l'exige la loi. Une fois que les données ne sont plus
                nécessaires, elles sont supprimées ou anonymisées de manière
                sécurisée.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Vos droits</h1>
              <p>
                En vertu du Règlement général sur la protection des données
                (RGPD) du Royaume-Uni, vous avez le droit :
                <br />• D'accéder à vos données personnelles.
                <br />• De corriger les données inexactes ou incomplètes.
                <br />• De demander la suppression de vos données.
                <br />• De restreindre la manière dont nous traitons vos
                données.
                <br />
                Pour exercer ces droits, veuillez nous contacter en utilisant
                les informations fournies dans la section 'Contact' ci-dessous.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Transferts internationaux de données</h1>
              <p>
                Si vos informations personnelles sont transférées en dehors du
                Royaume-Uni, nous nous assurons qu'elles sont protégées en
                respectant les cadres juridiques applicables, y compris les
                clauses contractuelles types ou des mesures équivalentes.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Informations de contact</h1>
              <p>
                Pour toute question relative à la confidentialité, ou pour
                exercer vos droits en vertu de cette politique, veuillez nous
                contacter via la section 'Contact' disponible sur notre page
                d'accueil.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Mises à jour de la politique</h1>
              <p>
                Nous pouvons mettre à jour cette Politique de Confidentialité de
                temps en temps. Si des modifications importantes sont apportées,
                nous vous en informerons via notre plateforme ou par e-mail.
                Veuillez consulter régulièrement cette page pour rester informé
                des mises à jour.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>Politique de Cookies de TürkAfrica.Directory</h6>
              <h6>Dernière mise à jour : 18 septembre 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Les cookies sont de petits fichiers texte stockés sur votre
                appareil pour améliorer l'expérience utilisateur. Cette
                Politique de Cookies explique comment et pourquoi nous utilisons
                des cookies sur la plateforme TürkAfrica.Directory.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Types de cookies que nous utilisons</h1>
              <p>
                Cookies essentiels : Ces cookies sont nécessaires aux
                fonctionnalités de base de la plateforme, telles que les
                connexions sécurisées et le traitement des transactions.
                <br />
                Cookies analytiques : Utilisés pour collecter des données sur la
                manière dont les utilisateurs interagissent avec notre
                plateforme, nous aidant à améliorer nos services.
                <br />
                Cookies marketing : Nous aident à personnaliser la publicité et
                les messages promotionnels en fonction de vos préférences et de
                votre comportement.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Gestion de vos cookies</h1>
              <p>
                Vous pouvez gérer ou bloquer les cookies via les paramètres de
                votre navigateur. Le consentement pour les cookies non
                essentiels est demandé lors de votre première visite sur notre
                plateforme et vous pouvez retirer votre consentement à tout
                moment.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Modifications de la Politique de Cookies</h1>
              <p>
                Nous pouvons mettre à jour cette Politique de Cookies de temps à
                autre pour refléter les changements dans nos pratiques ou les
                exigences légales. Une notification de toute mise à jour
                importante sera fournie via notre plateforme.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Informations de contact</h1>
              <p>
                Pour toute question ou préoccupation concernant cette Politique
                de Cookies, veuillez nous contacter via la section 'Contact' sur
                la page d'accueil.
              </p>
            </div>
          </>
        ) : lang == "/Portuguese" ? (
          <>
            <div className="sub_title_heading">
              <h6>Política de Privacidade da TürkAfrica.Directory</h6>
              <h6>Última Atualização: 18 de setembro de 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introdução</h1>
              <p>
                Bem-vindo à TürkAfrica.Directory, uma plataforma dedicada a
                promover conexões comerciais fortes entre a África e a Turquia.
                Esta Política de Privacidade explica como coletamos, usamos,
                protegemos e compartilhamos suas informações pessoais quando
                você usa nossos serviços.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Informações que coletamos</h1>
              <p>
                Coletamos as seguintes informações pessoais quando você se
                cadastra em nossa plataforma, faz pedidos ou interage com nossos
                serviços:
                <br />• Informações de Identificação Pessoal: Inclui seu nome,
                endereço de e-mail, número de telefone e detalhes comerciais.
                <br />• Informações Transacionais: Detalhes de transações e
                pedidos feitos através de nossa plataforma.
                <br />• Dados de Uso: Informações sobre como você navega e usa
                nosso site e serviços.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Como usamos seus dados</h1>
              <p>
                Utilizamos seus dados pessoais para:
                <br />• Fornecer nossos serviços: Processar transações, atender
                pedidos e oferecer suporte ao cliente.
                <br />• Melhorar nossa plataforma: Analisar o comportamento dos
                usuários para aprimorar a plataforma e desenvolver novos
                recursos.
                <br />• Marketing (com Consentimento): Enviar mensagens
                promocionais, ofertas especiais e oportunidades comerciais, mas
                apenas se você tiver optado por recebê-las.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Compartilhamento e Divulgação de Dados</h1>
              <p>
                Podemos compartilhar suas informações com:
                <br />• Prestadores de Serviços: Parceiros que nos ajudam a
                operar nossos negócios (por exemplo, processadores de
                pagamentos, serviços de logística).
                <br />• Conformidade Legal: Quando exigido por lei ou para
                proteger nossos direitos legais e interesses.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Segurança dos Dados</h1>
              <p>
                Levamos a segurança a sério e implementamos medidas de segurança
                padrão do setor, como criptografia e controles de acesso, para
                proteger suas informações pessoais contra acesso não autorizado,
                perda ou violação.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Retenção de Dados</h1>
              <p>
                Mantemos suas informações pessoais pelo tempo necessário para
                fornecer nossos serviços ou conforme exigido por lei. Quando não
                forem mais necessárias, suas informações são excluídas ou
                anonimizadas de forma segura.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Seus Direitos</h1>
              <p>
                Sob o Regulamento Geral de Proteção de Dados (RGPD) do Reino
                Unido, você tem o direito de:
                <br />• Acessar seus dados pessoais.
                <br />• Corrigir dados incorretos ou incompletos.
                <br />• Solicitar a exclusão de seus dados.
                <br />• Restringir a forma como processamos seus dados.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Transferências Internacionais de Dados</h1>
              <p>
                Se suas informações pessoais forem transferidas para fora do
                Reino Unido, garantimos que elas estejam protegidas, cumprindo
                os quadros jurídicos aplicáveis, incluindo cláusulas contratuais
                padrão ou medidas equivalentes.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Informações de Contato</h1>
              <p>
                Para qualquer dúvida relacionada à privacidade ou para exercer
                seus direitos sob esta política, entre em contato conosco
                através da seção ‘Contato’ disponível em nossa página inicial.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Atualizações da Política</h1>
              <p>
                Podemos atualizar esta Política de Privacidade de tempos em
                tempos. Se forem feitas mudanças significativas, notificaremos
                você através de nossa plataforma ou por e-mail. Consulte
                periodicamente para se manter informado sobre as atualizações.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>Política de Cookies da TürkAfrica.Directory</h6>
              <h6>Última Atualização: 18 de setembro de 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introdução</h1>
              <p>
                Cookies são pequenos arquivos de texto armazenados em seu
                dispositivo para melhorar a experiência do usuário. Esta
                Política de Cookies explica como e por que usamos cookies na
                plataforma TürkAfrica.Directory.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Tipos de Cookies que Usamos</h1>
              <p>
                Cookies Essenciais: Estes são necessários para as
                funcionalidades principais da plataforma, como logins seguros e
                processamento de transações.
                <br />
                Cookies Analíticos: Usados para coletar dados sobre como os
                usuários interagem com nossa plataforma, ajudando-nos a melhorar
                nossos serviços.
                <br />
                Cookies de Marketing: Nos ajudam a personalizar publicidade e
                mensagens promocionais com base em suas preferências e
                comportamento.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Gerenciamento de Cookies</h1>
              <p>
                Você pode gerenciar ou bloquear cookies através das
                configurações do seu navegador. O consentimento para cookies não
                essenciais é solicitado quando você visita nossa plataforma pela
                primeira vez e você pode retirar o consentimento a qualquer
                momento.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Alterações na Política de Cookies</h1>
              <p>
                Podemos atualizar esta Política de Cookies de tempos em tempos
                para refletir mudanças em nossas práticas ou requisitos legais.
                Avisos de qualquer atualização significativa serão fornecidos
                através de nossa plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Informações de Contato</h1>
              <p>
                Para qualquer dúvida ou preocupação sobre esta Política de
                Cookies, entre em contato conosco através da seção ‘Contato’ na
                página inicial.
              </p>
            </div>
          </>
        ) : lang == "/Swahili" ? (
          <>
            <div className="sub_title_heading">
              <h6>Sera ya Faragha ya TürkAfrica.Directory</h6>
              <h6>Imesasishwa Tarehe: 18 Septemba 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Utangulizi</h1>
              <p>
                Karibu kwenye TürkAfrica.Directory, jukwaa linaloongoza
                linalojitolea kukuza uhusiano wa biashara kati ya Afrika na
                Türkiye. Sera hii ya Faragha inaeleza jinsi tunavyokusanya,
                kutumia, kulinda, na kushiriki maelezo yako binafsi unapotumia
                huduma zetu.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Taarifa Tunazokusanya</h1>
              <p>
                Tunakusanya maelezo haya binafsi unaposajili kwenye jukwaa letu,
                kuweka maagizo, au kuingiliana na huduma zetu:
                <br />• Maelezo ya Utambulisho: Jina lako, barua pepe, namba ya
                simu, na maelezo ya biashara.
                <br />• Maelezo ya Muamala: Maelezo ya miamala na maagizo
                yaliyofanywa kupitia jukwaa letu.
                <br />• Data ya Matumizi: Taarifa kuhusu jinsi unavyotumia
                tovuti na huduma zetu.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Tunavyotumia Data Yako</h1>
              <p>
                Tunatumia data yako binafsi kwa:
                <br />• Kutoa Huduma zetu: Kuchakata miamala, kutimiza maagizo,
                na kutoa usaidizi kwa wateja.
                <br />• Kuboresha Jukwaa Letu: Kuchanganua tabia za watumiaji
                ili kuboresha jukwaa na kuendeleza vipengele vipya.
                <br />• Masoko (Kwa idhini): Kutuma ujumbe wa promosheni na ofa
                maalum, lakini tu ikiwa umechagua kupokea.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Kushiriki na Kufichua Data</h1>
              <p>
                Tunaweza kushiriki maelezo yako na:
                <br />• Watoa Huduma: Washirika wanaosaidia katika operesheni za
                biashara (k.m. uchakataji wa malipo, vifaa vya usafirishaji).
                <br />• Majukumu ya Kisheria: Tunapohitajika kisheria au kulinda
                haki zetu.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Usalama wa Data</h1>
              <p>
                Tunatekeleza hatua za usalama za kiwango cha sekta kama usimbaji
                fiche na udhibiti wa ufikiaji kulinda maelezo yako binafsi dhidi
                ya ufikiaji usioruhusiwa, upotezaji au uvunjaji.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Uhifadhi wa Data</h1>
              <p>
                Tunahifadhi maelezo yako binafsi kwa muda mrefu kama inahitajika
                kutoa huduma zetu au kama inavyotakiwa na sheria. Mara tu
                maelezo hayatakiwi tena, yanafutwa au kufanywa yasiyoweza
                kutambulika kwa usalama.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Haki Zako</h1>
              <p>
                Chini ya Kanuni ya Ulinzi wa Data ya Jumla ya Uingereza (GDPR),
                una haki ya kufikia maelezo yako binafsi, kusahihisha, kufuta au
                kuzuia usindikaji wa maelezo yako. Ili kutekeleza haki hizi,
                tafadhali wasiliana nasi kwa kutumia maelezo yaliyotolewa kwenye
                sehemu ya ‘Mawasiliano’.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Uhamishaji wa Data Kimataifa</h1>
              <p>
                Ikiwa maelezo yako binafsi yatahamishwa nje ya Uingereza,
                tunahakikisha yanalindwa kwa kufuata mifumo ya kisheria husika.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Taarifa za Mawasiliano</h1>
              <p>
                Kwa maswali yoyote yanayohusiana na faragha, au kutekeleza haki
                zako katika sera hii, tafadhali wasiliana nasi kupitia sehemu ya
                ‘Mawasiliano’ inapatikana kwenye ukurasa wa kutua.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Mabadiliko ya Sera</h1>
              <p>
                Tunaweza kusasisha Sera hii ya Faragha mara kwa mara. Ikiwa
                mabadiliko makubwa yatafanywa, tutakujulisha kupitia jukwaa letu
                au kwa barua pepe. Tafadhali angalia mara kwa mara ili kupata
                habari kuhusu sasisho.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>Sera ya Vidakuzi ya TürkAfrica.Directory</h6>
              <h6>Imesasishwa Tarehe: 18 Septemba 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Utangulizi</h1>
              <p>
                Vidakuzi ni faili ndogo za maandishi zinazohifadhiwa kwenye
                kifaa chako ili kuboresha uzoefu wa mtumiaji. Sera hii ya
                Vidakuzi inaeleza jinsi na kwa nini tunatumia vidakuzi kwenye
                jukwaa la TürkAfrica.Directory.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Aina za Vidakuzi Tunazotumia</h1>
              <p>
                Vidakuzi Muhimu: Hivi ni muhimu kwa utendakazi wa msingi wa
                jukwaa, kama vile kuingia kwa usalama na uchakataji wa miamala.
                <br />
                Vidakuzi vya Takwimu: Vinatumiwa kukusanya data juu ya jinsi
                watumiaji wanavyotumia jukwaa letu, na kutusaidia kuboresha
                huduma zetu.
                <br />
                Vidakuzi vya Masoko: Vinatusaidia kubinafsisha matangazo na
                ujumbe wa promosheni kulingana na mapendeleo yako na tabia yako.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Usimamizi wa Vidakuzi</h1>
              <p>
                Unaweza kudhibiti au kuzuia vidakuzi kupitia mipangilio ya
                kivinjari chako. Idhini ya vidakuzi visivyo muhimu huombwa
                wakati wa ziara yako ya kwanza kwenye jukwaa letu, na unaweza
                kuondoa idhini hiyo wakati wowote.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Mabadiliko katika Sera ya Vidakuzi</h1>
              <p>
                Tunaweza kusasisha Sera hii ya Vidakuzi mara kwa mara ili
                kuakisi mabadiliko katika mazoea yetu au mahitaji ya kisheria.
                Ilani ya sasisho zozote muhimu itatolewa kupitia jukwaa letu.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Taarifa za Mawasiliano</h1>
              <p>
                Kwa maswali au wasiwasi wowote kuhusu Sera hii ya Vidakuzi,
                tafadhali wasiliana nasi kupitia sehemu ya ‘Mawasiliano’ kwenye
                ukurasa wa kutua.
              </p>
            </div>
          </>
        ) : lang == "/Spanish" ? (
          <>
            <div className="sub_title_heading">
              <h6>Política de Privacidad de TürkAfrica.Directory</h6>
              <h6>Última Actualización: 18 de septiembre de 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introducción</h1>
              <p>
                Bienvenido a TürkAfrica.Directory, una plataforma líder dedicada
                a fomentar conexiones comerciales sólidas entre África y
                Turquía. Esta Política de Privacidad explica cómo recopilamos,
                usamos, protegemos y compartimos su información personal cuando
                utiliza nuestros servicios.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Información que recopilamos</h1>
              <p>
                Recopilamos la siguiente información personal cuando se registra
                en nuestra plataforma, realiza pedidos o interactúa con nuestros
                servicios:
                <br />• Información de Identificación Personal: Incluye su
                nombre, dirección de correo electrónico, número de teléfono y
                detalles comerciales.
                <br />• Información Transaccional: Detalles de las transacciones
                realizadas a través de nuestra plataforma.
                <br />• Datos de Uso: Información sobre cómo navega y utiliza
                nuestro sitio web y servicios.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Cómo usamos sus datos</h1>
              <p>
                Utilizamos sus datos personales para:
                <br />• Proporcionar nuestros servicios: Procesar transacciones,
                cumplir pedidos y ofrecer asistencia al cliente.
                <br />• Mejorar nuestra plataforma: Analizar el comportamiento
                de los usuarios para mejorar la plataforma y desarrollar nuevas
                funciones.
                <br />• Marketing (Con consentimiento): Enviar mensajes
                promocionales, ofertas especiales y oportunidades comerciales,
                pero solo si ha dado su consentimiento.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Compartición y Divulgación de Datos</h1>
              <p>
                Podemos compartir su información con:
                <br />• Proveedores de Servicios: Socios que nos ayudan a operar
                nuestros negocios (por ejemplo, procesadores de pagos, servicios
                de logística).
                <br />• Cumplimiento legal: Cuando la ley lo requiera o para
                proteger nuestros derechos legales e intereses.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Seguridad de los Datos</h1>
              <p>
                Tomamos la seguridad en serio e implementamos medidas de
                seguridad estándar de la industria, como cifrado y controles de
                acceso, para proteger su información personal contra acceso no
                autorizado, pérdida o violación.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Retención de Datos</h1>
              <p>
                Conservamos su información personal durante el tiempo necesario
                para cumplir con nuestros servicios o según lo requiera la ley.
                Una vez que ya no sea necesario, sus datos se eliminan o se
                anonimizarán de forma segura.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Sus Derechos</h1>
              <p>
                Bajo el Reglamento General de Protección de Datos (RGPD) del
                Reino Unido, usted tiene el derecho de:
                <br />• Acceder a sus datos personales.
                <br />• Corregir datos inexactos o incompletos.
                <br />• Solicitar la eliminación de sus datos.
                <br />• Restringir cómo procesamos sus datos.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Transferencias Internacionales de Datos</h1>
              <p>
                Si su información personal se transfiere fuera del Reino Unido,
                garantizamos que esté protegida cumpliendo con los marcos
                legales aplicables, incluidas cláusulas contractuales estándar o
                medidas equivalentes.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Información de Contacto</h1>
              <p>
                Para cualquier consulta relacionada con la privacidad o para
                ejercer sus derechos en virtud de esta política, comuníquese con
                nosotros a través de la sección ‘Contacto’ disponible en nuestra
                página de inicio.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Actualizaciones de la Política</h1>
              <p>
                Podemos actualizar esta Política de Privacidad de vez en cuando.
                Si se realizan cambios significativos, le notificaremos a través
                de nuestra plataforma o por correo electrónico. Consulte
                periódicamente para mantenerse informado sobre las
                actualizaciones.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>Política de Cookies de TürkAfrica.Directory</h6>
              <h6>Última Actualización: 18 de septiembre de 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introducción</h1>
              <p>
                Las cookies son pequeños archivos de texto almacenados en su
                dispositivo para mejorar la experiencia del usuario. Esta
                Política de Cookies explica cómo y por qué utilizamos cookies en
                la plataforma TürkAfrica.Directory.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Tipos de Cookies que Utilizamos</h1>
              <p>
                Cookies esenciales: Estas son necesarias para las funciones
                básicas de la plataforma, como inicios de sesión seguros y
                procesamiento de transacciones.
                <br />
                Cookies analíticas: Utilizadas para recopilar datos sobre cómo
                los usuarios interactúan con nuestra plataforma, lo que nos
                ayuda a mejorar nuestros servicios.
                <br />
                Cookies de marketing: Nos ayudan a personalizar la publicidad y
                los mensajes promocionales en función de sus preferencias y
                comportamiento.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Gestión de Cookies</h1>
              <p>
                Puede administrar o bloquear cookies a través de la
                configuración de su navegador. El consentimiento para cookies no
                esenciales se solicita cuando visita nuestra plataforma por
                primera vez y puede retirar su consentimiento en cualquier
                momento.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Cambios en la Política de Cookies</h1>
              <p>
                Podemos actualizar esta Política de Cookies de vez en cuando
                para reflejar cambios en nuestras prácticas o requisitos
                legales. Se proporcionará un aviso de cualquier actualización
                significativa a través de nuestra plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Información de Contacto</h1>
              <p>
                Para cualquier consulta o inquietud sobre esta Política de
                Cookies, comuníquese con nosotros a través de la sección
                'Contacto' en la página de inicio.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="sub_title_heading">
              <h6>TürkAfrica.Directory Privacy Policy</h6>
              <h6>Last Updated: September 18, 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Welcome to TürkAfrica.Directory, a leading platform dedicated to
                fostering strong business connections between Africa and Turkey.
                This Privacy Policy explains how we collect, use, protect, and
                share your personal information when you use our services.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Information We Collect</h1>
              <p>
                We collect the following personal information when you register
                on our platform, place orders, or interact with our services:
                <br />• Personal Identification Information: Includes your name,
                email address, phone number, and business details.
                <br />• Transactional Information: Details of transactions and
                orders made through our platform.
                <br />• Usage Data: Information on how you browse and use our
                website and services.
              </p>
            </div>
            <div className="text_div">
              <h1>3. How We Use Your Data</h1>
              <p>
                We use your personal data to:
                <br />• Provide Our Services: Process transactions, fulfill
                orders, and provide customer support.
                <br />• Improve Our Platform: Analyze user behavior to enhance
                the platform and develop new features.
                <br />• Marketing (With Consent): Send promotional messages,
                special offers, and business opportunities, but only if you have
                opted in to receive them.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Data Sharing and Disclosure</h1>
              <p>
                We may share your information with:
                <br />• Service Providers: Partners who assist in running our
                business operations (e.g., payment processors, logistics
                services).
                <br />• Legal Compliance: When required by law or to protect our
                legal rights and interests.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Data Security</h1>
              <p>
                We take security seriously and implement industry-standard
                security measures like encryption and access controls to protect
                your personal information from unauthorized access, loss, or
                breach.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Data Retention</h1>
              <p>
                We retain your personal information for as long as necessary to
                fulfill our services or as required by law. Once no longer
                needed, your data is securely deleted or anonymized.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Your Rights</h1>
              <p>
                Under the UK General Data Protection Regulation (GDPR), you have
                the right to:
                <br />• Access your personal data.
                <br />• Correct inaccurate or incomplete data.
                <br />• Request the deletion of your data.
                <br />• Restrict how we process your data.
              </p>
            </div>
            <div className="text_div">
              <h1>8. International Data Transfers</h1>
              <p>
                If your personal information is transferred outside of the UK,
                we ensure it is protected by complying with applicable legal
                frameworks, including standard contractual clauses or equivalent
                safeguards.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Contact Information</h1>
              <p>
                For any privacy-related questions, or to exercise your rights
                under this policy, please contact us via the ‘Contact’ section
                available on our landing page.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Policy Updates</h1>
              <p>
                We may update this Privacy Policy from time to time. If
                significant changes are made, we will notify you via our
                platform or by email. Please check regularly to stay informed
                about updates.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>TürkAfrica.Directory Cookie Policy</h6>
              <h6>Last Updated: September 18, 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Cookies are small text files stored on your device to improve
                user experience. This Cookie Policy explains how and why we use
                cookies on the TürkAfrica.Directory platform.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Types of Cookies We Use</h1>
              <p>
                Essential Cookies: These are necessary for core functionality,
                such as secure logins and transaction processing.
                <br />
                Analytical Cookies: Used to collect data on how users interact
                with our platform, helping us improve our services.
                <br />
                Marketing Cookies: Help us personalize advertising and
                promotional messages based on your preferences and behavior.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Managing Your Cookies</h1>
              <p>
                You can manage or block cookies through your browser settings.
                Consent for non-essential cookies is requested when you first
                visit our platform, and you may withdraw consent at any time.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Changes to the Cookie Policy</h1>
              <p>
                We may update this Cookie Policy from time to time to reflect
                changes in our practices or legal requirements. Notice of any
                significant updates will be provided via our platform.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Contact Information</h1>
              <p>
                For any questions or concerns about this Cookie Policy, please
                contact us via the ‘Contact’ section on the landing page.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
